

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import PricingTable from './components/PricingTable.vue';
import MastHead from './components/MastHead.vue';
import ContactUs from '@/components/shared/ContactUs.vue';

@Component({
  components: {
    PricingTable,
    ContactUs,
    MastHead
  },
})
export default class PricingPage extends Vue {}
